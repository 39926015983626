<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        
        <ion-title v-html="pageTitle"></ion-title>
        <!-- <theme-selector></theme-selector> -->
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
     <slot/>
      
     
    </ion-content>
  </ion-page>
</template>
<script >
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent } from '@ionic/vue';
// import ThemeSelector from "@/components/ThemeSelector.vue";

export default  {
  
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage },
  props:["pageTitle"]
}
</script>