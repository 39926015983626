<template>
<div class="segment-container">
  <ion-segment @ionChange="segmentChanged($event)" value="25px">
    <ion-segment-button class="btn" value="35px">
      <ion-label>اكبر</ion-label>
    </ion-segment-button>
    <ion-segment-button class="btn" value="25px">
      <ion-label>وسط</ion-label>
    </ion-segment-button>
    <ion-segment-button class="btn" value="20px">
      <ion-label>اصغر</ion-label>
    </ion-segment-button>
  </ion-segment>
  </div>
</template>

<script>

export default {
    emits:['font-size-changed'],
    
    methods:{
        segmentChanged(e){
            this.$emit("font-size-changed",e.target.value);
           
           console.log(e.target.value)
        }
    }
};
</script>

<style lang="scss" scoped>
.segment-container{
    padding-left:15px;
    padding-right:15px;
}
ion-segment {
    
  --background: var(--my-gray);
}
.btn {
    padding-top:5px;
    padding-bottom:5px;
  * {
    font-family: "Almarai";
  }
  --color:var(--my-black);
 --background: var(--my-gray);
  --color-checked:var(--my-black);
}
</style>